<template>

  <b-card no-body>
    <div class="m-1">
      <b-row>
        <b-col cols="12" md="8" class="d-flex align-items-center justify-content-start mb-1 mb-md-0 per-page-select">
          <b-row class="flex-nowrap">
            <v-select
                v-model="perPage"
                class="per-page-selector d-inline-block mx-50 perPageFilter"
                :options="perPageOptions"
                :clearable="false"/>
            <v-select
                v-model="statusFilter"
                :options="formStatusFilterOptions"
                :reduce="val => val.value"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50 statusFilter"/>
            <v-select v-model="userId" :clearable="true" :options="userOptions" label="label" :reduce="val => val.value" class="per-page-selector mx-50 userFilter">
              <template #option="{ avatar, label }">
                <b-avatar size="sm" :src="getApiFile(avatar)" :text="(label!=null && !busy ? avatarText(label) : '')"/>
                <span class="ml-50 align-middle"> {{ label }}</span>
              </template>
              <template #selected-option="{ avatar, label }">
                <b-avatar size="sm" class="border border-white" :src="getApiFile(avatar)" :text="(label!=null && !busy ? avatarText(label) : '')"/>
                <span class="ml-50 align-middle"> {{ label }}</span>
              </template>
            </v-select>
            <flat-pickr v-model="startDateFilter" class="form-control dateFilter mx-50" :config="{altInput: true, altFormat: 'd.m.Y', dateFormat: 'Y-m-d', locale: { firstDayOfWeek: 1}, weekStart: 1}"/>
            <flat-pickr v-model="endDateFilter" class="form-control dateFilter mx-50" :config="{altInput: true, altFormat: 'd.m.Y', dateFormat: 'Y-m-d', locale: { firstDayOfWeek: 1}, weekStart: 1}"/>
          </b-row>
        </b-col>
        <b-col cols="12" md="4">
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input v-model="searchQuery" class="d-none mr-1" placeholder="Arama..."/>
            <header-table :new-route="{ name: 'form-advance-add' }" :re-fetch-data="reFetchData" :export-excel="exportExcel"></header-table>
          </div>
        </b-col>
      </b-row>
    </div>

    <b-table
        ref="refListTable"
        primary-key="id"
        empty-text="Kayıt bulunamadı"
        :class="`position-relative table-sticky ${totalCount > 0 && totalCount < 5 ? 'dropdown-h150': ''}`"
        :items="fetchList"
        :fields="tableColumns"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        responsive striped bordered hover show-empty>

      <template #cell(id)="data">
        <id-column-table :data="data.value" :route-name="'form-advance-edit'"></id-column-table>
      </template>

      <template #cell(formNo)="data">
        <div class="text-nowrap">{{ data.value }}</div>
      </template>

      <template #cell(userPicture)="data">
        <div class="text-nowrap">
          <b-avatar v-if="data.item.userName" size="32" variant="light-primary" :src="getApiFile(data.value)" :text="avatarText(data.item.userName)"/>
          <span class="ml-1">{{ data.item.userName }}</span>
        </div>
      </template>

      <template #cell(advanceFormTypeName)="data">
        <div class="text-nowrap">{{ data.value }}</div>
      </template>

      <template #cell(advanceFormReasonTypeName)="data">
        <div class="text-nowrap">{{ data.value }}</div>
      </template>

      <template #cell(total)="data">
        <div class="text-nowrap">{{ data.value }}</div>
      </template>

      <template #cell(currencyTypeName)="data">
        <div class="text-nowrap">{{ data.value }}</div>
      </template>

      <template #cell(advanceFormReasonTypeName)="data">
        <div class="text-nowrap">{{ data.value }}</div>
      </template>

      <template #cell(formStatusName)="data">
        <b-badge pill :variant="`light-${recordFormStatusVariant(data.item)}`" class="text-capitalize">
          {{ data.value }}
        </b-badge>
      </template>

      <template #cell(createdOn)="data">
        <date-column-table :data="data.value"></date-column-table>
      </template>

      <template #cell(actions)="data">
        <div class="text-nowrap">
          <b-dropdown text="Dropdown" variant="link" no-caret toggle-class="p-0" dropleft>
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body"/>
            </template>
            <b-dropdown-item :to="{ name: 'form-advance-edit', params: { id: data.item.id } }">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">Değiştir</span>
            </b-dropdown-item>
            <b-dropdown-item @click="cancel(data.item)">
              <feather-icon icon="XIcon"/>
              <span class="align-middle ml-50">İptal</span>
            </b-dropdown-item>
            <remove-button-table :data="data" :re-fetch-data="reFetchData" :dropdown="true"></remove-button-table>
          </b-dropdown>
          <feather-icon :id="`row-${data.item.id}-prev`" icon="EyeIcon" size="16" @click="previewRecord(data.item)" class="mx-1"/>
          <b-tooltip title="Görüntüle" class="cursor-pointer" :target="`row-${data.item.id}-prev`"/>
        </div>
      </template>

    </b-table>

    <div class="mx-2 mb-2">
      <b-row>
        <footer-table :data-meta="dataMeta"></footer-table>
        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
          <b-pagination class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item" v-model="currentPage" :total-rows="totalCount" :per-page="perPage" first-number last-number>
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18"/>
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18"/>
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>

    <Overlay :busy="busy"></Overlay>
    <preview :is-preview-modal.sync="isPreviewModal" :data-record.sync="dataPreviewRecord"></preview>
  </b-card>

</template>

<script>
import {BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, BTooltip, BButtonToolbar, BButtonGroup, BOverlay, BModal, BCardText,} from 'bootstrap-vue'
import {FooterTable, RemoveButtonTable, EditButtonTable, DateColumnTable, ActiveColumnTable, BooleanColumnTable, IdColumnTable, HeaderTable} from '@/components/Table'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import store from '@/store'
import useList from './useList'
import storeModule from './store'
import userModule from "@/views/organization/employee/store"
import Overlay from "@/components/Overlay.vue"
import Preview from "@/views/form/advance/Preview.vue"
import {avatarText, formStatusFilterOptions, getApiFile, recordFormStatusVariant} from "@core/utils/filter"
import DateTimeColumnTable from "@/components/Table/DateTimeColumnTable.vue"
import Vue from "vue"
import {toastMessage} from "@core/utils/utils";
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    DateTimeColumnTable,
    flatPickr,
    BCardText,
    BModal,

    HeaderTable, FooterTable, RemoveButtonTable, EditButtonTable, Preview,
    DateColumnTable, ActiveColumnTable, BooleanColumnTable, IdColumnTable,

    BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem,
    BPagination, BTooltip, BButtonToolbar, BButtonGroup, BOverlay,

    vSelect,
    Overlay,
  },
  setup() {
    const STORE_MODULE_NAME = 'store'
    const STORE_USER_MODULE_NAME = 'store-user'

    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, storeModule)
      store.registerModule(STORE_USER_MODULE_NAME, userModule)
    }
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) {
        store.unregisterModule(STORE_MODULE_NAME)
        store.unregisterModule(STORE_USER_MODULE_NAME)
      }
    })

    const {
      tableColumns,
      perPage,
      currentPage,
      totalCount,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      busy,
      userId,
      statusFilter,
      startDateFilter,
      endDateFilter,

      fetchList,
      reFetchData,
    } = useList()

    const dataPreviewRecord = ref({})
    const isPreviewModal = ref(false)
    const previewRecord = (dataItem) => {
      dataPreviewRecord.value = dataItem
      isPreviewModal.value = true
    }

    const cancel = (dataItem) => {
      Vue.swal({
        title: 'Onaylıyor musunuz?',
        text: 'Kayıt iptal edilecektir!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet, İptal',
        cancelButtonText: 'Vazgeç',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('store/cancelItem', {id: dataItem.id}).then(response => {
            if (response.data.success) {
              reFetchData()
              Vue.swal({
                icon: 'success',
                title: 'Başarılı!',
                text: 'Kayıt iptal edildi.',
                confirmButtonText: 'Tamam',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
              })
            }
          }).catch(error => {
            Vue.swal({
              icon: 'error',
              title: 'Hata!',
              text: error.response.data.message,
              confirmButtonText: 'Tamam',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            })
          })
        }
      })
    }

    const userOptions = ref([])
    busy.value = true
    store.dispatch('store-user/fetchItems', {
      sortColumn: 'firstName',
      sortDesc: false,
      isActive: true,
    }).then(response => {
      response.data.data.forEach((value, index) => {
        if (value.isActive) {
          if (!userOptions.value.some(e => e.value === value.id)) {
            userOptions.value.push({label: value.firstName + ' ' + value.lastName, value: value.id, avatar: value.profilePicture})
          }
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    const exportExcel = () => {
      Vue.swal({
        title: 'Onaylıyor musunuz?',
        text: 'Excele aktarım yapılacaktır!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet, Aktar',
        cancelButtonText: 'Vazgeç',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('store/export', {
            userId: userId.value,
            formStatusId: statusFilter.value,
            startDate: startDateFilter.value,
            endDate: endDateFilter.value,
          }).then(response => {
            if (response.data.success) {
              window.open(getApiFile(response.data.data), '_blank')
            }
          }).catch(error => {
            Vue.swal({
              icon: 'error',
              title: 'Hata!',
              text: error.response.data.message,
              confirmButtonText: 'Tamam',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            })
          })
        }
      })
    }

    return {
      tableColumns,
      perPage,
      currentPage,
      totalCount,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      busy,
      isPreviewModal,
      dataPreviewRecord,
      userId,
      userOptions,
      statusFilter,
      startDateFilter,
      endDateFilter,

      formStatusFilterOptions,

      fetchList,
      reFetchData,
      previewRecord,
      getApiFile,
      avatarText,
      recordFormStatusVariant,
      cancel,
      exportExcel,
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.statusFilter {
  min-width: 22%;
}

.userFilter {
  min-width: 35%;
}

.dateFilter {
  min-width: 20%;
}

.perPageFilter {
  min-width: 12%;
}

</style>
